<template>
  <div
    class="d-flex align-items-center"
    style="height: 36px; gap: 12px"
  >
    <h4
      class="font-weight-bolder mb-0"
      style="min-width: 165px"
    >
      {{ getCurrentWeek() }}
    </h4>

    <div
      class="d-flex"
      style="gap: 12px"
    >
      <b-button
        class="chevron-btn--custom"
        @click="prevMonth"
      >
        <feather-icon
          icon="LArrowUpLineIcon"
          size="22"
        />
      </b-button>
      <b-button
        class="chevron-btn--custom"
        @click="nextMonth"
      >
        <feather-icon
          icon="LArrowDownLineIcon"
          size="22"
        />
      </b-button>
    </div>
    <div>
      <b-button
        class="this-month-btn"
        variant="success"
        :disabled="checkThisMonth"
        @click="setCurrentDate"
      >
        {{ $t('Today') }}
      </b-button>
    </div>
    <div class="h-100">
      <component
        :is="warehouseWeeklyOrderFields['selectDayOfWeek'].type"
        style="width: 400px; margin-left: 5px"
        class="bg-white"
        v-bind="getProps('selectDayOfWeek', warehouseWeeklyOrderFields)"
      />
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { getProps } from '@core/utils/utils'
import config from '../../config'

export default {
  name: 'ManageWeeklyDate',
  components: {
    BButton,
  },
  data() {
    return {
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      currentDay: new Date().getDay(),
    }
  },
  computed: {
    checkThisMonth() {
      return this.currentMonth === new Date().getMonth() && this.currentYear === new Date().getFullYear()
    },
  },
  methods: {
    prevMonth() {
      const newDate = new Date(this.currentYear, this.currentMonth - 1)
      this.changeDate(newDate)
      this.$emit('prevMonth', { currentMonth: this.currentMonth, currentYear: this.currentYear })
    },
    nextMonth() {
      const newDate = new Date(this.currentYear, this.currentMonth + 1)
      this.changeDate(newDate)
      this.$emit('nextMonth', { currentMonth: this.currentMonth, currentYear: this.currentYear })
    },
    changeDate(newDate) {
      this.currentMonth = newDate.getMonth()
      this.currentYear = newDate.getFullYear()
    },
    getCurrentWeek() {
      const curr = new Date() // get current date
      const first = curr.getDate() - curr.getDay() + 1 // First day is the day of the month - the day of the week
      const last = first + 6 // last day is the first day + 6

      return `${new Date(curr.setDate(first)).toLocaleDateString('en-us', { month: 'short' })}. ${new Date(curr.setDate(first)).toLocaleDateString('en-us', { day: 'numeric' })} —  ${new Date(curr.setDate(last)).toLocaleDateString('en-us', { day: 'numeric' })} '${new Date(curr.setDate(last)).toLocaleDateString('en-us', { year: 'numeric' })}`
    },
    setCurrentDate() {
      this.currentMonth = new Date().getMonth()
      this.currentYear = new Date().getFullYear()
      this.$emit('currentMonth', { currentMonth: this.currentMonth, currentYear: this.currentYear })
    },
  },
  setup() {
    const { warehouseWeeklyOrderFields } = config()
    return {
      warehouseWeeklyOrderFields,
      getProps,
    }
  },
}
</script>

<style lang="scss" scoped>
.chevron-btn--custom {
  min-width: 36px;
  height: 36px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.this-month-btn {
  min-width: 115px;
  height: 36px;
  padding: 0;

  &:disabled {
    cursor: not-allowed;
  }
}
</style>
