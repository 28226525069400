<template>
  <div class="mt-1">
    <week-list-skeleton v-if="loading" />
    <div v-else>
      <div class="w-100 bg-white mb-1 h-auto">
        <span v-if="getWarehouseOrdersList.unresolved.length" class="d-block w-100 text-center font-medium-5 font-weight-bolder">{{ 'Unresolved' }}</span>
        <div
          v-for="weekDay in Object.keys(getWarehouseOrdersList.unresolved)"
          :key="weekDay"
          class="w-100"
        >
          <div
            class="w-100 mb-1 h-auto"
            :style="`background-color: ${weekDay === getToday ? '#00B13926' : '#fff'}`"
          >
            <span class=" d-block w-100 text-center font-medium-5 font-weight-bolder mb-1">
              {{ weekDay }}
            </span>
            <span
              v-for="hour in Object.keys(getWarehouseOrdersList.orders[weekDay])"
              :key="hour"
              class="d-flex align-items-end flex-row mb-1"
            >
              <span
                class="pl-1 font-weight-bolder"
                style="width: 100px"
              >{{ hour }}</span>
              <span
                v-for="order in getWarehouseOrdersList.orders[weekDay][hour]"
                :key="order"
                class="text-white"
                :style="`height: 30px; width: 92%; background-color: ${orderStatesAndStatuses[order.status].style.color}`"
              >
                <feather-icon
                  icon="LWarehouseIcon"
                  size="16"
                  style="margin: 8px"
                  class="text-white stroke-current"
                />
                {{ order._order_id }}
              </span>
            </span>
          </div>

        </div>
      </div>
      <div
        v-for="weekDay in Object.keys(getWarehouseOrdersList.orders)"
        :key="weekDay"
        class="w-100"
      >
        <div
          class="w-100 mb-1 h-auto"
          :style="`background-color: ${weekDay === getToday ? '#00B13926' : '#fff'}`"
        >
          <span class=" d-block w-100 text-center font-medium-5 font-weight-bolder mb-1">
            {{ weekDay }}
          </span>
          <span
            v-for="hour in Object.keys(getWarehouseOrdersList.orders[weekDay])"
            :key="hour"
            class="d-flex align-items-end flex-row mb-1"
          >
            <span
              class="pl-1 font-weight-bolder"
              style="width: 100px"
            >{{ hour }}</span>
            <span
              v-for="order in getWarehouseOrdersList.orders[weekDay][hour]"
              :key="order"
              class="text-white"
              :style="`height: 30px; width: 92%; background-color: ${orderStatesAndStatuses[order.status].style.color}`"
            >
              <feather-icon
                icon="LWarehouseIcon"
                size="16"
                style="margin: 8px"
                class="text-white stroke-current"
              />
              {{ order._order_id }}
            </span>
          </span>
        </div>

      </div>
    </div>
    <portal to="body-footer">
      <div>
        <manage-weekly-date />
      </div>
    </portal>
  </div>
</template>
<script>
import ManageWeeklyDate from '@/views/main/warehouse/main-order/components/use-as-global/ManageWeeklyDate.vue'
import WeekListSkeleton from '@/views/main/warehouse/main-order/components/skeletons/WeekListSkeleton.vue'
import config from '../../config'

export default {
  name: 'WeekListByOrders',
  components: { WeekListSkeleton, ManageWeeklyDate },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    getWarehouseOrdersList() {
      return this.$store.state[this.MODULE_NAME].warehouseOrdersList
    },
    getToday() {
      const day = new Date().toLocaleDateString('en-us', { day: 'numeric' })
      const weekDay = new Date().toLocaleDateString('en-us', { weekday: 'short' })
      return `${weekDay} ${day}`
    },
    getWeekDaysArray() {
      const array = []
      const curr = new Date() // get current date
      for (let i = 1; i < 7; i++) {
        const arrayItem = curr.getDate() - curr.getDay() + i
        const data = `${new Date(curr.setDate(arrayItem)).toLocaleDateString('en-us', { weekday: 'long' })}. ${new Date(curr.setDate(arrayItem)).toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' })}`
        array.push(data)
      }
      return array
    },
  },
  created() {
    this.loading = true
    this.$store.dispatch(`${this.MODULE_NAME}/getByProducts`).then(res => {
      const { data } = res.data
      this.$store.commit(`${this.MODULE_NAME}/WAREHOUSE_ORDERS_LIST`, data)
    }).finally(() => {
      this.loading = false
    })
  },

  setup() {
    const { MODULE_NAME, orderStatesAndStatuses } = config()

    return {
      MODULE_NAME,
      orderStatesAndStatuses,
    }
  },
}
</script>
