<template>
  <div>
    <div class="w-100 bg-white mb-1 h-auto pb-1">
      <span class=" d-block w-100 text-center font-medium-5 font-weight-bolder">{{ 'Unresolved' }}</span>
      <b-skeleton
        v-for="item in 4"
        :key="item"
        style="width: 100%; height: 80px; margin: 5px"
        class="mb-1"
      />
    </div>
    <div
      v-for="hour in getWeekDaysArray"
      :key="hour"
      class="w-100 bg-white mb-1 h-auto pb-1"
    >
      <span class=" d-block w-100 text-center font-medium-5 font-weight-bolder">{{ hour }}</span>
      <b-skeleton
        v-for="item in 4"
        :key="item"
        style="width: 100%; height: 80px; margin: 5px"
        class="mb-1"
      />
    </div>
  </div></template>
<script>
import { BSkeleton } from 'bootstrap-vue'

export default {
  name: 'WeekListSkeleton',
  components: {
    BSkeleton,
  },
  computed: {
    getWeekDaysArray() {
      const array = []
      const curr = new Date() // get current date
      for (let i = 1; i < 7; i++) {
        const arrayItem = curr.getDate() - curr.getDay() + i
        const data = `${new Date(curr.setDate(arrayItem)).toLocaleDateString('en-us', { weekday: 'long' })}. ${new Date(curr.setDate(arrayItem)).toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' })}`
        array.push(data)
      }
      return array
    },
  },
}
</script>
